import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsComponent } from './views.component';
import { ViewRoutingModule } from './views.routing';
import { SharedModule } from '../shared/shared.module';
import { NotificationAnimationType, SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({
  declarations: [ViewsComponent,
  ],
  imports: [
    CommonModule,
    ViewRoutingModule,
    SharedModule,
    SimpleNotificationsModule.forRoot({      
      timeOut: 10000,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: false,
      position: ["middle", "right"],
      animate: NotificationAnimationType.FromTop,
      maxLength: 5
    })
  ],
  exports: [],
  providers: [],
})
export class ViewsModule { }
