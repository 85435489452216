import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsComponent } from './views.component';
import { ErrorComponent } from './error/error.component';

let routes: Routes = [
  {
    path: '',
    component: ViewsComponent,
    pathMatch: 'full'
  },
  { path: 'web', loadChildren: () => import('./ace-layout/ace-layout.module').then(m => m.AceLayoutModule) },
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '/web/dashboard' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewRoutingModule { }
