import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest, HttpClient, HttpErrorResponse, HttpHeaders,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuth2Response } from './OAuth2Response';
import { catchError, delay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpRequestService } from './http-request.service';

import { Observable, throwError } from 'rxjs';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';


@Injectable({
    providedIn: 'root',
})
export class HttpsRequestInterceptor implements HttpInterceptor {
    private usuario: OAuth2Response;
    constructor(private http: HttpClient, private router: Router, private OAuth: HttpRequestService) {
        this.usuario = new OAuth2Response();
    }

    cont: any = 0;
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const self = this;
        const dupReq = req.clone({
            headers: req.url.includes("ftp") ? req.headers : req.headers.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('_access_token'))),
        });

        dupReq.headers.set('Access-Control-Allow-Origin', '*');
        dupReq.headers.set('Access-Control-Allow-Method', 'GET,POST,OPTIONS,DELETE,PUT');
        return next
            .handle(dupReq)
            .pipe(
                tap((ev: HttpEvent<any>) => { }),
                catchError(response => {
                    if (response instanceof HttpErrorResponse) {
                        if (response.status === 401) {
                            let mMessage = response.error.message.toLowerCase();
                            let user = localStorage.getItem('username');
                            if (isNullOrUndefined(user)) {
                                this.router.navigate(['/user/login']);
                                return;
                            } else if ((mMessage.indexOf('authentication') >= 0) && (mMessage.indexOf('required') >= 0)) {
                                self.OAuth.refretoken(JSON.parse(localStorage.getItem('_refresh_token'))).then((res) => {
                                    window.location.reload();
                                    return;
                                }).catch((erro) => {
                                    this.router.navigate(['/user/login']);
                                    console.error('Erro ao Reconectar, tente logar novamente');
                                })
                                return;
                            } else return throwError(response);
                        } else {
                            return throwError(response);
                        }
                    } else {
                        return throwError(response);
                    }
                })
            )
    }

}

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpsRequestInterceptor,
            multi: true,
        },
    ]
})
export class InterceptorModule {
}
