import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { InterceptorModule } from './views/ace-layout/auth/interceptor.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { LayoutContainersModule } from './layout/layout.containers.module';
import { BootstrapModule } from './layout/components/bootstrap/bootstrap.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    TranslateModule.forRoot(),
    InterceptorModule,
    HttpClientModule,
    BootstrapModule,
    NgxUiLoaderModule.forRoot({
      delay: 200,
      maxTime: 300000,
      logoUrl: "/assets/img/images/logo-black1.png",
      logoSize: 60,
      blur: 10,
      fgsType: "rectangle-bounce-pulse-out",
      fgsSize: 30,
      fgsColor: "#003499",
      hasProgressBar: false,
      gap: 5,
      text: "Carregando",
      textColor: "#003499",
      textPosition: "center-center",
    }),
    // AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [
    AppComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
