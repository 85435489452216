import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html'
})
export class ViewsComponent implements OnInit {

  constructor(private router: Router, private notifications: NotificationsService,) {
    // If you have landing page, remove below line and implement it here.
    // this.router.navigateByUrl('/web/dashboard');
  }

  ngOnInit() {
    this.router.navigateByUrl('/web/dashboard');
  }

}
