import { Injectable, DebugElement } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class EstampadorServicesService {
  private Accept = 'application/json';
  private content = 'multipart/form-data';
  private token: any;
  constructor(private router: Router, private http: HttpClient, private ngxService: NgxUiLoaderService) { }

  DELDATA = (param: string) => {
    this.ngxService.start();
    const url = `${environment.url}/api/stamper` + param;
    return new Promise((resolve, reject) => {
      this.http.delete(url, {
      })
        .toPromise()
        .then(
          res => {
            this.ngxService.stop();
            resolve(res);
          }
        ).catch(data => {
          this.ngxService.stop();
          reject(data);
        })
        ;
    });
  }

  PUTDATA = (body: object, param: string) => {
    this.ngxService.start();
    const url = `${environment.url}/api/stamper` + param;
    return new Promise((resolve, reject) => {
      this.http.put(url, body, {
      })
        .toPromise()
        .then(
          res => {
            this.ngxService.stop();
            resolve(res);
          }
        ).catch(data => {
          this.ngxService.stop();
          reject(data);
        })
        ;
    });
  }

  POSTDATA = (body: object, param: string) => {
    this.ngxService.start();
    const url = `${environment.url}/api/stamper` + param;
    return new Promise((resolve, reject) => {
      this.http.post(url, body, {
      })
        .toPromise()
        .then(
          res => {
            this.ngxService.stop();
            resolve(res);
          }
        ).catch(data => {
          this.ngxService.stop();
          reject(data);
        });
    });
  }

  GETDATA = (param: string) => {
    this.ngxService.start();
    const url = `${environment.url}/api/stamper` + param;
    return new Promise((resolve, reject) => {
      this.http.get(url, {
      })
        .toPromise()
        .then(
          data => {
            this.ngxService.stop();
            resolve(data);
          }
        ).catch(error => {
          this.ngxService.stop();
          reject(error);
        })
        ;
    });
  }

  GETDATA2 = (param: string) => { //SEM LOADING
    const url = `${environment.url}/api/stamper` + param;
    return new Promise((resolve, reject) => {
      this.http.get(url, {
      })
        .toPromise()
        .then(
          data => {
            resolve(data);
          }
        ).catch(error => {
          reject(error);
        })
        ;
    });
  }


  GETrelatorio = (param: string) => {
    this.ngxService.start();
    const url = `${environment.url}` + '/api/report' + param;
    return new Promise((resolve, reject) => {
      this.http.get(url, {
      })
        .toPromise()
        .then(
          data => {
            this.ngxService.stop();
            resolve(data);
          }
        ).catch(error => {
          this.ngxService.stop();
          reject(error);
        })
        ;
    });
  }

  
  GETNfeToken = (empresaId: any) => {
    this.ngxService.start();
    const url = `${environment.url}` + '/api/nfe/token/' + empresaId;
    return new Promise((resolve, reject) => {
      this.http.get(url, {
      }).toPromise()
        .then(
          data => {
            this.ngxService.stop();
            resolve(data);
          }
        ).catch(error => {
          this.ngxService.stop();
          reject(error);
        })
        ;
    });
  }
}